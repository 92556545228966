var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分类ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.categoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "categoryId", _vm._n($$v))
                  },
                  expression: "formData.categoryId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品标题:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品短标题，简称:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.shortTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "shortTitle", $$v)
                  },
                  expression: "formData.shortTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品主图:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.cover,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "cover", $$v)
                  },
                  expression: "formData.cover"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "售价:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.price,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "price", _vm._n($$v))
                  },
                  expression: "formData.price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "市场价:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.marketingPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "marketingPrice", _vm._n($$v))
                  },
                  expression: "formData.marketingPrice"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品状态:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "status", _vm._n($$v))
                  },
                  expression: "formData.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "虚拟商品:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.virtual,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "virtual", $$v)
                  },
                  expression: "formData.virtual"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否售罄:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.sellOut,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "sellOut", $$v)
                  },
                  expression: "formData.sellOut"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "商品子图:" } }),
          _c(
            "el-form-item",
            { attrs: { label: "商品详情:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.details,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "details", $$v)
                  },
                  expression: "formData.details"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.thirdProductId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "thirdProductId", $$v)
                  },
                  expression: "formData.thirdProductId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "第三方skuID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.thirdSkuId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "thirdSkuId", $$v)
                  },
                  expression: "formData.thirdSkuId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "平台ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.platformId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "platformId", _vm._n($$v))
                  },
                  expression: "formData.platformId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "销量:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.saleCount,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "saleCount", _vm._n($$v))
                  },
                  expression: "formData.saleCount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品得分:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.score,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "score", _vm._n($$v))
                  },
                  expression: "formData.score"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推广次数:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.promotionTimes,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "promotionTimes", _vm._n($$v))
                  },
                  expression: "formData.promotionTimes"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推广订单数:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.promotionOrderCount,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "promotionOrderCount", _vm._n($$v))
                  },
                  expression: "formData.promotionOrderCount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否发布:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.publish,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "publish", $$v)
                  },
                  expression: "formData.publish"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主图:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.ossCoverPath,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "ossCoverPath", $$v)
                  },
                  expression: "formData.ossCoverPath"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }